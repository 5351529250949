<template>
  <div>
    <label class="text-field-label">Communications</label>
    <div
      class="mx-4"
      id="block-time-slot-list"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-data-table
        :headers="headers"
        :items="fileHistories"
        :options.sync="options"
        :loading="loading"
        :items-per-page="1"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        :server-items-length="totalFiles"
        :footer-props="{
          'items-per-page-options': [5, 10, 25, -1],
        }"
        @update:options="fetch"
        @click:row="(item) => item.url && reviewFile(item.url)"
      >
        <template v-slot:item.supplier="{ item }">
          {{ item.supplier?.company_name || '' }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ formatTime(item.created_at) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="item.url"
            size="default"
            @click="reviewFile(item.url)"
            >{{ icons.eyesOn }}</v-icon
          >
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Log send file data
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import { mdiEye } from '@mdi/js'
import infiniteScroll from 'vue-infinite-scroll'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import { dateFormat } from '@/utils/date'
import { perPageFilter } from '@/utils/helpers'

export default {
  components: {},

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin],

  directives: {
    infiniteScroll,
  },

  props: {
    maintenanceId: {
      required: true,
      type: [String, Number],
    },
  },

  data() {
    return {
      defaultFilter: 'created_at',
      sortOrder: true,
      headers: [
        {
          text: 'Supplier',
          value: 'supplier',
          sortable: false,
        },
        {
          text: 'Type',
          value: 'type',
          sortable: false,
        },
        {
          text: 'Send Date',
          value: 'created_at',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
      ],
      icons: {
        eyesOn: mdiEye,
      },
      loading: false,
      options: {},
      blockTimeSlotMeta: 0,
    }
  },
  mounted() {
    this.fetch()
  },
  watch: {},
  computed: {
    ...mapState({
      fileHistories: (state) => state.maintenance.sendFileHistories,
      totalFiles: (state) => state.maintenance.totalSendFileHistories,
    }),
  },
  methods: {
    ...mapActions({
      getFileHistories: 'maintenance/getFileHistories',
    }),

    async fetch(options) {
      if (this.loading) return

      this.loading = true
      let params = {
        page: options?.page || 1,
        maintenanceId: this.maintenanceId,
        itemsPerPage: perPageFilter(options, this.totalFiles, 5),
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '-created_at',
      }
      await this.getFileHistories(params).finally(() => {
        this.loading = false
      })
    },

    formatTime(time) {
      return dateFormat(time)
    },

    reviewFile(url) {
      if (url) {
        this.openRemoteFile(url)
      }
    },

    openRemoteFile(fileUrl) {
      const fileExtension = fileUrl.split('.').pop().toLowerCase()
      const supportedExtensions = [
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'ppt',
        'pptx',
      ]

      if (supportedExtensions.includes(fileExtension)) {
        const viewerUrl = `https://docs.google.com/gview?url=${encodeURIComponent(
          fileUrl
        )}&embedded=true`
        window.open(viewerUrl, '_blank')
      } else {
        window.open(fileUrl, '_blank')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.show-hide-slots-btn {
  display: flex;
  margin: 30px 0 0 auto;
}
</style>
