<template>
  <v-autocomplete
    class="select-tag"
    :value="value"
    :items="validItems"
    :item-text="textColumn"
    :item-value="valueColumn"
    :placeholder="placeholder"
    :loading="loading || fetching"
    :disabled="disabled || fetching"
    :deletable-chips="deletableChips || false"
    filled
    v-bind="$attrs"
    :chips="!!value.length"
    :multiple="multiple"
    @input="onChange"
  />
</template>
<script>
/**
 * ==================================================================================
 * Select Urgency
 * ==================================================================================
 **/
import { mapActions, mapMutations } from 'vuex'

export default {
  props: {
    value: {
      type: [String, Number, Array],
      default: null,
    },

    placeholder: {
      type: String,
      default: 'Select tag',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    valueColumn: {
      type: String,
      default: 'id',
    },

    textColumn: {
      type: String,
      default: 'label',
    },

    preSelect: {
      type: Boolean,
      default: false,
    },

    preFetch: {
      type: Boolean,
      default: true,
    },

    buildingId: {
      type: [String, Number],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    if (this.preFetch) {
      this.fetch()
    }
  },

  data() {
    return {
      fetching: false,
      tags: [],
    }
  },

  computed: {
    validItems() {
      return this.tags && this.tags.length ? this.tags : []
    },
  },

  methods: {
    ...mapActions({
      getTags: 'tag/getForSelects',
    }),

    ...mapMutations({
      clearTags: 'tag/clearTagList',
    }),

    async fetch() {
      if (this.fetching) return

      this.fetching = true
      this.clearTags()

      let params = {
        filter: {
          buildingId: this.buildingId,
        },
      }

      await this.getTags(params)
        .then((data) => {
          let tags =
            data && data.length
              ? data.filter(
                  (tag) =>
                    tag.is_active || (this.value && this.value.includes(tag.id))
                )
              : []

          this.tags = tags
          if (this.preSelect && tags.length) {
            this.onChange(tags[0].id)
          }

          this.$emit('populated', this.tags)
        })
        .finally(() => {
          this.fetching = false
        })
    },

    onChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>
