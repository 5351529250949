<template>
  <VuePdfApp :pdf="pdfUrl" :config="config" :page-scale="auto" />
</template>

<script>
import VuePdfApp from 'vue-pdf-app'
import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: { VuePdfApp },

  props: {
    file: {
      type: [File, Object],
      default: null,
    },
  },

  watch: {
    file: {
      immediate: true,
      async handler(newFile) {
        await this.handleReadFile(newFile)
      },
    },
  },

  methods: {
    async handleReadFile(file) {
      if (
        (file instanceof Blob || file instanceof File) &&
        file.type === 'application/pdf'
      ) {
        // Create a URL for the file
        this.pdfUrl = URL.createObjectURL(file)
      } else if (file instanceof Object) {
        this.pdfUrl = file.full_url
      } else {
        this.pdfUrl = null // Clear URL if the file is not a PDF
      }
    },
  },

  data() {
    return {
      pdfUrl: null,

      config: {
        toolbar: {
          toolbarViewerLeft: {
            findbar: false,
            previous: false,
            next: false,
            pageNumber: false,
          },
          toolbarViewerRight: {
            presentationMode: false,
            openFile: false,
            print: false,
            download: false,
            viewBookmark: false,
          },
          toolbarViewerMiddle: {
            zoomOut: true,
            zoomIn: true,
            scaleSelectContainer: true,
          },
        },
      },
    }
  },
}
</script>
<style lang="scss">
#toolbarViewerRight,
#toolbarViewerLeft {
  display: none;
}

#scaleSelectContainer {
  width: 110px;
}
</style>
