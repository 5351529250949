<template>
  <div class="log-section">
    <div class="header">
      <h3>Logs</h3>
    </div>

    <div v-if="fetching" class="text-center">
      <v-progress-linear
        color="primary accent-4"
        indeterminate
        height="5"
      ></v-progress-linear>
    </div>

    <!-- Comment List -->
    <div class="log-items mt-6">
      <div v-for="(log, index) in logs" :key="index" class="log-item">
        <p>
          <span class="log-line" v-html="logFormat(log)"></span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import { dateFormat } from '@/utils/date'
import STATUS from '@/utils/enums/MaintenanceStatus'

export default {
  mixins: [SnackbarMixin, ErrorHandlerMixin],
  props: {
    preFetch: {
      type: Boolean,
      default: true,
    },
    maintenanceId: {
      required: true,
      type: [String, Number],
    },
  },

  created() {
    if (this.preFetch) {
      this.clearLogs()
      this.fetch()
    }
  },

  data() {
    return {
      options: {},
      fetching: false,
    }
  },

  computed: {
    ...mapState({
      logs: (state) => state.maintenance.logs,
    }),
  },

  methods: {
    dateFormat,
    ...mapActions({
      getLogs: 'maintenance/getLogs',
    }),
    ...mapMutations({
      clearLogs: 'maintenance/clearLogs',
    }),

    openRemoteFile(fileUrl) {
      const fileExtension = fileUrl.split('.').pop().toLowerCase()
      const supportedExtensions = [
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'ppt',
        'pptx',
      ]

      if (supportedExtensions.includes(fileExtension)) {
        const viewerUrl = `https://docs.google.com/gview?url=${encodeURIComponent(
          fileUrl
        )}&embedded=true`
        window.open(viewerUrl, '_blank')
      } else {
        window.open(fileUrl, '_blank')
      }
    },

    logFormat(log) {
      let template = log.template

      const fileUrl = log.file_url
      if (fileUrl) {
        template += ` <a href="javascript:void(0)" onclick="window.dispatchEvent(new CustomEvent('open-remote-file', { detail: '${fileUrl}' }))" class="primary--text" style="text-decoration:none;">View file</a>`
      }

      return template
        .replace(
          ':full_name',
          `<span class="primary--text">${
            log.type === 'assigned_to'
              ? log.new_value
              : log.user?.full_name || 'Unknown user'
          }</span>`
        )
        .replace(
          ':supplier',
          `<span class="primary--text">${
            log.supplier?.company_name || 'Unknown supplier'
          }</span>`
        )
        .replace(
          ':old_value',
          `<span class="primary--text">${
            log.type === 'due_date_update'
              ? log.old_value
                ? dateFormat(log.old_value, 'DD/MM/YYYY')
                : '-'
              : this.getStatusName(log.old_value) || ''
          }</span>`
        )
        .replace(
          ':new_value',
          `<span class="primary--text">${
            log.type === 'due_date_update'
              ? dateFormat(log.new_value, 'DD/MM/YYYY')
              : this.getStatusName(log.new_value) || ''
          }</span>`
        )
        .replace(
          ':date_time',
          `<span style="font-weight:normal;">${dateFormat(
            log.created_at,
            'DD/MM/YYYY hh:mm'
          )}</span>`
        )
    },

    getStatusName(value) {
      const status = STATUS.find((item) => item.value === value)
      return status ? status.text : value
    },

    async fetch() {
      if (this.fetching) return

      this.fetching = true

      let params = {
        maintenanceId: this.maintenanceId,
        sort: '-created_at',
        pagination: false,
      }

      await this.getLogs(params).finally(() => {
        this.fetching = false
      })
    },
  },

  mounted() {
    // Listen for the custom event to open remote files
    window.addEventListener('open-remote-file', (event) => {
      this.openRemoteFile(event.detail)
    })
  },

  beforeDestroy() {
    // Clean up the event listener
    window.removeEventListener('open-remote-file', (event) => {
      this.openRemoteFile(event.detail)
    })
  },
}
</script>
<style scoped>
.log-section {
  width: 100%;
  padding: 10px;
}

.log-line {
  font-weight: bold;
}

.log-items {
  min-height: 100px;
  max-height: 300px;
  overflow: scroll;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
}
</style>
