<template>
  <v-autocomplete
    class="select-supplier"
    required
    :value="value"
    :items="validItems"
    :item-text="textColumn"
    :item-value="valueColumn"
    :placeholder="placeholder"
    :loading="loading || fetching"
    :disabled="disabled || fetching"
    :deletable-chips="deletableChips"
    :multiple="multiple"
    v-bind="$attrs"
    :chips="multiple ? value.length > 0 : !!value"
    @input="onChange"
  ></v-autocomplete>
</template>
<script>
/**
 * ==================================================================================
 * Select Supplier
 * ==================================================================================
 **/
import { mapActions, mapMutations } from 'vuex'

export default {
  props: {
    value: {
      type: [String, Number, Array],
      default: null,
    },

    placeholder: {
      type: String,
      default: 'Select supplier',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    valueColumn: {
      type: String,
      default: 'id',
    },

    textColumn: {
      type: String,
      default: 'company_name',
    },

    role: {
      type: [String],
      default: null,
    },

    preSelect: {
      type: Boolean,
      default: false,
    },

    preFetch: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    buildingId: {
      type: [String, Number],
      default: null,
    },
  },

  created() {
    if (this.preFetch) {
      this.fetch()
    }
  },

  data() {
    return {
      fetching: false,
      suppliers: [],
    }
  },

  computed: {
    validItems() {
      return this.suppliers && this.suppliers.length ? this.suppliers : []
    },
  },

  methods: {
    ...mapActions({
      getSuppliers: 'supplier/getSuppliers',
    }),

    ...mapMutations({
      clearSuppliers: 'supplier/clearSupplierList',
    }),

    async fetch() {
      if (this.fetching) return

      this.fetching = true
      this.clearSuppliers()

      let params = {
        buildingId: this.buildingId,
      }

      await this.getSuppliers(params)
        .then((data) => {
          this.suppliers = data

          if (this.preSelect && this.validItems.length) {
            this.onChange(this.validItems[0].id)
          }

          this.$emit('populated', this.suppliers)
        })
        .finally(() => {
          this.fetching = false
        })
    },

    onChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>
