const STATUS = [
  {
    text: 'Pending',
    value: 'pending',
    tier: 1,
  },
  {
    text: 'In progress',
    value: 'in_progress',
    tier: 2,
  },
  {
    text: 'In Progress - Quote',
    value: 'in_progress_quote',
    tier: 3,
  },
  {
    text: 'In Progress - Assigned',
    value: 'in_progress_assigned',
    tier: 4,
  },
  {
    text: 'In Progress - Waiting',
    value: 'in_progress_waiting',
    tier: 5,
  },
  {
    text: 'In Progress - Inspection',
    value: 'in_progress_inspection',
    tier: 6,
  },
  {
    text: 'Cancelled',
    value: 'cancelled',
    tier: 7,
  },
  {
    text: 'Finished',
    value: 'finished',
    tier: 8,
  },
]

export default STATUS
