<template>
  <v-autocomplete
    class="select-asset"
    :value="value"
    :items="validItems"
    :item-text="textColumn"
    :item-value="valueColumn"
    :placeholder="placeholder"
    :loading="loading || fetching"
    :disabled="disabled || fetching"
    :deletable-chips="deletableChips"
    v-bind="$attrs"
    :multiple="multiple"
    filled
    :chips="!!value.length"
    @input="onChange"
  />
</template>
<script>
/**
 * ==================================================================================
 * Select Asset
 * ==================================================================================
 **/
import { mapActions, mapMutations } from 'vuex'

export default {
  props: {
    value: {
      type: [String, Number, Array],
      default: null,
    },

    placeholder: {
      type: String,
      default: 'Select asset',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    valueColumn: {
      type: String,
      default: 'id',
    },

    textColumn: {
      type: String,
      default: 'name',
    },

    preSelect: {
      type: Boolean,
      default: false,
    },

    preFetch: {
      type: Boolean,
      default: true,
    },
    buildingId: {
      type: [String, Number],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    if (this.preFetch) {
      this.fetch()
    }
  },

  data() {
    return {
      fetching: false,
      assets: [],
    }
  },

  computed: {
    validItems() {
      return this.assets && this.assets.length ? this.assets : []
    },
  },

  watch: {
    buildingId: {
      handler(newValue, oldValue) {
        this.fetch()
        this.$emit('input', '')
      },
    },
  },

  methods: {
    ...mapActions({
      getAssets: 'asset/getForSelects',
    }),

    ...mapMutations({
      clearAssets: 'asset/clearAssetList',
    }),

    async fetch() {
      if (this.fetching) return

      this.fetching = true
      this.clearAssets()

      let params = {
        filter: {
          buildingId: this.buildingId,
        },
      }

      await this.getAssets(params)
        .then((data) => {
          this.assets = data

          if (this.preSelect && this.validItems.length) {
            this.onChange(this.validItems[0].id)
          }

          this.$emit('populated', this.assets)
        })
        .finally(() => {
          this.fetching = false
        })
    },

    onChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>
