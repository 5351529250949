<template>
  <div class="comment-section">
    <!-- Add Comment Button -->
    <div class="header">
      <h3>Comments</h3>
      <v-btn
        color="primary"
        class="add-comment-btn"
        height="40px"
        @click="dialogAdd = true"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Add Comment
      </v-btn>
    </div>

    <div v-if="fetching" class="text-center">
      <v-progress-linear
        color="primary accent-4"
        indeterminate
        height="5"
      ></v-progress-linear>
    </div>

    <div v-else-if="comments.length === 0" class="no-comments mt-2">
      No comments found.
    </div>

    <!-- Comment List -->
    <div class="comment-items">
      <div
        v-for="(comment, index) in comments"
        :key="index"
        class="comment-item"
      >
        <template v-if="comment.author">
          <img
            v-if="!!thumbSrc(comment.author)"
            class="avatar"
            :src="thumbSrc(comment.author)"
            alt="User Avatar"
          />
          <v-avatar v-else class="avatar" color="primary" size="40">
            <span class="white--text text-h6">{{
              comment.author.full_name.charAt(0).toUpperCase()
            }}</span>
          </v-avatar>
          <div class="comment-content">
            <div class="comment-body">
              <strong>{{ comment.author.full_name }}</strong>
              <p>{{ comment.body }}</p>
            </div>
            <div>
              <span class="comment-time">{{
                dateFormat(comment.created_at, 'DD MMM hh:mm')
              }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>

    <v-dialog v-model="dialogAdd" max-width="600px">
      <v-form ref="form" class="faq-form__form" @submit.prevent="submit">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Open Dialog
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">New Comment</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <label class="text-field-label">Comment</label>
                  <v-textarea
                    flat
                    solo
                    required
                    class="mt-2"
                    placeholder="Comment"
                    v-model="form.body"
                    :error-messages="form.$getError('body')"
                    :loading="form.$busy"
                    :disabled="form.$busy"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 "
              text
              @click="dialogAdd = false"
            >
              Cancel
            </v-btn>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 text-uppercase"
              text
              @click="submit()"
              name="submitForm"
              :loading="form.$busy"
              :disabled="!form.body"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { dateFormat } from '@/utils/date'
import Form from '@/utils/form'
import { mdiPlus } from '@mdi/js'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'

export default {
  mixins: [SnackbarMixin, ErrorHandlerMixin],
  props: {
    preFetch: {
      type: Boolean,
      default: true,
    },
    maintenanceId: {
      required: true,
      type: [String, Number],
    },
  },

  created() {
    if (this.preFetch) {
      this.fetch()
    }
  },

  data() {
    return {
      form: new Form({
        body: '',
      }),
      options: {},
      dialogAdd: false,
      fetching: false,
      icons: {
        add: mdiPlus,
      },
    }
  },

  computed: {
    ...mapState({
      comments: (state) => state.maintenance.commentList,
    }),
  },

  methods: {
    dateFormat,
    ...mapActions({
      getComments: 'maintenance/getComments',
      createCommand: 'maintenance/createComment',
    }),
    ...mapMutations({
      clearComments: 'maintenance/clearCommentList',
    }),

    thumbSrc(author) {
      return author.avatar && (author.avatar.thumb_url || author.avatar.url)
        ? author.avatar.thumb_url || author.avatar.url
        : ''
    },

    async submit() {
      if (this.form.$busy) return

      this.form.$busy = true
      this.form.$clearErrors()

      const data = {
        formData: this.getFormData(),
        id: this.maintenanceId,
      }

      await this.createCommand(data)
        .then(() => {
          this.dialogAdd = false
          this.form.$busy = false
          this.form = new Form({
            body: '',
          })
          this.fetch()
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    async fetch() {
      if (this.fetching) return

      this.fetching = true

      let params = {
        maintenanceId: this.maintenanceId,
        sort: '-created_at',
        pagination: false,
      }

      await this.getComments(params).finally(() => {
        this.fetching = false
      })
    },

    getFormData() {
      let form = this.form.$data()

      const formData = new FormData()
      for (var field in form) {
        formData.append(field, form[field])
      }
      return formData
    },
  },
}
</script>
<style scoped>
.comment-section {
  width: 100%;
  padding: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
}

.add-comment-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 5px;
}
.comment-items {
  min-height: 100px;
  max-height: 300px;
  overflow: scroll;
}
.comment-item {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.comment-content {
  flex: 1;
}

.comment-body {
  background-color: #dad3bf4d;
  padding: 10px 15px;
}

.comment-content strong {
  font-size: 1.1em;
  display: block;
}

.comment-content p {
  margin: 5px 0;
}

.comment-time {
  font-size: 0.8em;
  color: #777;
  text-align: right;
  display: block;
  margin-top: 10px;
}
</style>
