<template>
  <v-select
    class="select-urgency"
    :value="value"
    :items="validItems"
    :item-text="textColumn"
    :item-value="valueColumn"
    :placeholder="placeholder"
    :loading="loading || fetching"
    :disabled="disabled || fetching"
    v-bind="$attrs"
    @input="onChange"
  />
</template>
<script>
/**
 * ==================================================================================
 * Select Urgency
 * ==================================================================================
 **/
import { mapActions, mapMutations } from 'vuex'

export default {
  props: {
    value: {
      type: [String, Number],
      default: null,
    },

    placeholder: {
      type: String,
      default: 'Select Urgency',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    valueColumn: {
      type: String,
      default: 'id',
    },

    textColumn: {
      type: String,
      default: 'label',
    },

    preSelect: {
      type: Boolean,
      default: false,
    },

    preFetch: {
      type: Boolean,
      default: true,
    },
    buildingId: {
      type: [String, Number],
      default: null,
    },
  },

  created() {
    if (this.preFetch) {
      this.fetch()
    }
  },

  data() {
    return {
      fetching: false,
      urgencies: [],
    }
  },

  computed: {
    validItems() {
      return this.urgencies && this.urgencies.length ? this.urgencies : []
    },
  },

  watch: {
    buildingId: {
      handler(newValue, oldValue) {
        this.fetch()
        this.$emit('input', '')
      },
    },
  },

  methods: {
    ...mapActions({
      getUrgencies: 'urgency/getForSelects',
    }),

    ...mapMutations({
      clearUrgencies: 'urgency/clearUrgencyList',
    }),

    async fetch() {
      if (this.fetching) return

      this.fetching = true
      this.clearUrgencies()

      let params = {
        filter: {
          buildingId: this.buildingId,
          sort: 'position',
        },
      }

      await this.getUrgencies(params)
        .then((data) => {
          this.urgencies = data

          if (this.preSelect && this.validItems.length) {
            this.onChange(this.validItems[0].id)
          }

          this.$emit('populated', this.urgencies)
        })
        .finally(() => {
          this.fetching = false
        })
    },

    onChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>
